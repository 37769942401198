<template>
  <div class="depute-maintena">
    <ContactInfo></ContactInfo>
    <img
      class="animate__animated"
      v-scroll="'animate__fadeIn'"
      src="@assets/images/DeputeMaintena1.png"
      alt=""
      width="100%"
    />
    <div class="number1">
      <img src="@assets/images/SystemBuild2.png" alt="" width="100%" />
      <div class="contain">
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p style="color: white; font-weight: normal !important">服务介绍</p>
        </div>
        <p
          class="Rem0_24 animate__animated"
          v-scroll="'animate__fadeIn'"
          style="animation-delay: 1s; text-indent: 2em"
        >
          景翔拥有近二十年管理体系建设的咨询经验，拥有一直专业的师资团队，成功辅导过几干家企业。由于外部环境不断变化导致管理体系也随之变化，而企业的体系往往严重滞后，所以，有部分顾客一直委托景翔负责其体系的维护和提升，在我们的贴身服务中陪伴了企业共同发展壮大。若您的企业有此需求可将管理体系给我们托管。
        </p>
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p
            style="
              text-align: start;
              color: white;
              font-weight: normal !important;
            "
          >
            服务内容：
          </p>
        </div>
        <div class="ul">
          <span
            class="Rem0_24 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list1"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_24 animate__animated"
            v-scroll="'animate__fadeInRightBig'"
            style="animation-delay: 1s; white-space: pre-wrap"
            v-for="(item, index) in serveList.list2"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_24 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list3"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
      </div>
    </div>
    <div class="number2">
      <img src="../../../assets/images/DeputeMaintena2.png" alt="" />

      <div class="contain">
        <div class="Rem0_4">
          <p>体系委托的特点</p>
          <p></p>
        </div>
        <div class="box">
          <div>
            <img src="../../../assets/images/DeputeMaintena6.png" alt="" />
            <div>
              <p class="Rem0_24">一对一服务</p>
              <p class="Rem0_20">
                根据顾客的业态和管理体系现状水平，景翔会派遣对口专家负责维护和提升，建立专门服务档案，一对一的保姆式服务。
              </p>
            </div>
          </div>
          <div>
            <img src="../../../assets/images/DeputeMaintena7.png" alt="" />
            <div>
              <p class="Rem0_24">菜单式服务</p>
              <p class="Rem0_20">
                每个企业管理体系的基础和水平千差万别，景翔首先推出菜单式服务，企业可以根据自己的需要从菜单中选取需要的部分委托景翔维护和提升。
              </p>
            </div>
          </div>
          <div style="background: #48b0f4">
            <img src="../../../assets/images/DeputeMaintena8.png" alt="" />
            <div>
              <p class="Rem0_24">优势汲取</p>
              <p class="Rem0_20">
                由于景翔有二十多年的咨询历史，熟悉各行各业管理体系的特点，并且有很多成功的案例，我们帮顾客体系维护时可以将别人的强项移植到顾客的体系上。
              </p>
            </div>
          </div>
          <div>
            <img src="../../../assets/images/DeputeMaintena9.png" alt="" />
            <div>
              <p class="Rem0_24">省人省事</p>
              <p class="Rem0_20">
                委托景翔体系维护后，可以较少您的企业80%体系维护人员，且维护更加专业、可信和有效，更易被企业高层和外部认可。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="number3">
      <img src="@assets/images/DeputeMaintena3.png" alt="" width="100%" />

      <div class="contain">
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p style="color: #4375c8">为什么要体系委托维护</p>
          <p></p>
        </div>
        <p class="Rem0_28">企业管理体系常见问题</p>
        <div class="animate__animated" v-scroll="'animate__fadeInLeftBig'">
          <p class="Rem0_28"><span>1</span>文件没有系统性</p>
          <p class="Rem0_20">
            文件多，内容重叠，没有系统性，员工无法知道到底有多少文件与自己有关，文件本来是方便管理的，现在反而构成负担。
          </p>
        </div>
        <div class="animate__animated" v-scroll="'animate__fadeInRightBig'">
          <p class="Rem0_28"><span>2</span>文件跟不上变化</p>
          <p class="Rem0_20">
            因企业业务发展、内外部环境变化、顾客的要求不断增多和变化，体系文件跟不上变化，成为摆设，严重制约企业的发展。
          </p>
        </div>
        <div class="animate__animated" v-scroll="'animate__fadeInLeftBig'">
          <p class="Rem0_28"><span>3</span>文件各自为政</p>
          <p class="Rem0_20">
            体系文件各部门各自为政，文件变更无法做到相关文件的同步更改，造成相互矛盾，大大降低了文件的可用性，带来维护困难。
          </p>
        </div>
        <div class="animate__animated" v-scroll="'animate__fadeInRightBig'">
          <p class="Rem0_28"><span>4</span>两张皮</p>
          <p class="Rem0_20">
            写一套做一套，对外一套对内一套，两张皮，对认证机构的审核、对顾客的审厂都是应急式的应付式的，虚耗人力资源。
          </p>
        </div>
        <div class="animate__animated" v-scroll="'animate__fadeInLeftBig'">
          <p class="Rem0_28"><span>5</span>缺少专业人员</p>
          <p class="Rem0_20">
            体系人员流动性造成专业体系维护人员缺乏，导致体系累积混乱，出现很多地方存在严重缺陷，无法确保顺利通过外部的审核，也严重影响业务的正常开展。
          </p>
        </div>
      </div>
    </div>
    <div class="number4">
      <img src="../../../assets/images/DeputeMaintena4.png" alt="" />
      <div class="contain">
        <div
          class="Rem0_4 animate__animated"
          v-scroll="'animate__fadeInLeftBig'"
        >
          <p>为您的体系保驾护航</p>
          <p></p>
        </div>
        <div class="boxdiv">
          <div
            v-for="(item, index) in boxList"
            :key="index"
            class="animate__animated"
            v-scroll="'animate__rollIn'"
          >
            <img :src="item.img" alt="" />
            <p class="Rem0_28">{{ item.p1 }}</p>
            <p class="Rem0_20">{{ item.p2 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="number5">
      <img src="../../../assets/images/DeputeMaintena5.png" alt="" />
      <div class="contain">
        <div class="Rem0_4">
          <p>体系委托维护流程</p>
          <p></p>
        </div>
        <div
          class="text1 text Rem0_24 animate__animated"
          v-scroll="'animate__fadeInDownBig'"
        >
          <span>顾客从菜单中选择体系委托项目，或度身定做</span>
          <span>双方签订合同</span>
          <span>景翔派遣专业老师专项负责</span>
          <span>专业老师制定年度体系维护方案</span>
        </div>
        <div
          class="text2 text Rem0_24 animate__animated"
          v-scroll="'animate__fadeInUp'"
        >
          <span>每次服务，并提交《顾问报告》</span>
          <span>景翔提供年度体系维护报告</span>
          <span>顾客认可</span>
          <span>顾客有需要时再续约</span>
          <img
            class="animate__animated"
            v-scroll="'animate__bounceIn'"
            src="../../../assets/images/DeputeMaintena16.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactInfo from "../../../components/public/ContactInfo.vue";
export default {
  components: { ContactInfo },
  data() {
    return {
      serveList: {
        list1: [
          "经营路线图维护和更新",
          "职能分解维护和更新",
          "岗位职责维护和更新",
          "目标指标维护和更新",
          "体系树维护和更新",
        ],
        list2: [
          "二层文件维护和更新",
          "管理类三层文件维护\n和更新",
          "各层文件接口维护\n和更新",
          "管理类表单的维护\n和更新审",
          "核检查表的维护和更新",
        ],
        list3: [
          "管理手册维护和更新",
          "因标准更新的体系升版",
          "年度内部审核",
          "客户审核应对",
          "年度管理评审",
        ],
      },
      boxList: [
        {
          img: require("../../../assets/images/DeputeMaintena10.png"),
          p1: "确保通过认证机构年度审核",
          p2: "顾客委托景翔体系维护包括了委托内部审核，景翔以其专业性可让顾客轻松通过认证机构的年度外部审核",
        },
        {
          img: require("../../../assets/images/DeputeMaintena11.png"),
          p1: "专项体系维护服务",
          p2: "除了景翔预设的服务菜单外，顾客还可以根据自己的发展需要提出需求，景翔将根据顾客的需求策划专门的体系维护和提升方案。",
        },
        {
          img: require("../../../assets/images/DeputeMaintena12.png"),
          p1: "灵活的服务周期",
          p2: "以一年为最小服务周期，若服务效果满意再续约，也可选择跨年度委托服务方式，一次签约超过三年委托体系维护服务，可享受8折优惠",
        },
        {
          img: require("../../../assets/images/DeputeMaintena13.png"),
          p1: "灵活的服务方式",
          p2: "若顾客不需要体系全部委托维护服务，可采用年卡制、充值制，有需要时随时让景翔提供服务，这是机动灵活的服务方式",
        },
        {
          img: require("../../../assets/images/DeputeMaintena14.png"),
          p1: "总有适合的培训",
          p2: "景翔每年都向顾客提供最新的几十个培训课程清单，顾客可在清单中任意选择两个合适的课程，景翔免费提供",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.depute-maintena {
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .number5 {
      .contain {
        img {
          right: 3% !important;
          top: -115% !important;
          width: 14% !important;
        }
        .text1 {
          span:nth-of-type(1) {
            left: 16% !important;
            width: 2rem !important;
          }
          span:nth-of-type(3) {
            left: 55% !important;
            width: 1.5rem !important;
          }
          span:nth-of-type(4) {
            left: 72% !important;
            width: 1.5rem !important;
          }
        }
        .text2 {
          span:nth-of-type(1) {
            width: 1.8rem !important;
          }
          span:nth-of-type(2) {
            width: 1.5rem !important;
          }
          span:nth-of-type(4) {
            width: 1.2rem !important;
          }
        }
      }
    }
  }
  .contain {
    .Rem0_4 {
      text-align: center;
      color: #4375c8;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 3%;
      }
    }
  }

  .number1 {
    position: relative;
    > img {
      height: 9rem;
    }
    .contain {
      width: 80%;
      margin: 0 10%;
      color: white;
      text-align: start;
      position: absolute;
      top: 0;
      .Rem0_4:nth-of-type(1) {
        margin: 5% 0 3.5% 0;
        display: flex;
        justify-content: center;
      }
      .Rem0_4:nth-of-type(2) {
        margin: 2.5% 0 0.5% 0 !important;
      }
      .ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        opacity: 0.83;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          border: 1px solid;
          text-align: center;
          border: 1px solid #909090;
          border-radius: 5px;
          margin: 0 6px;
          margin-top: 20px;
          background: linear-gradient(to right, #555974, #5d616f);
          cursor: default;
          height: 1rem;
          line-height: 0.3rem !important;
        }
      }
    }
  }
  .number2 {
    > img {
      position: absolute;
      width: 100%;
    }
    .contain {
      width: 60%;
      margin: 0 20%;
      position: absolute;
      .Rem0_4 {
        margin: 5% 0 8% 0;
      }
      .box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        > div {
          width: 42.2%;
          margin-top: 2.5%;
          background: #4c69f4;
          display: flex;
          color: white;
          padding: 2% 2.5% 2% 2%;
          border-radius: 0.12rem;
          p:nth-of-type(1) {
            font-weight: bold;
          }
          p:nth-of-type(2) {
            line-height: 0.32rem !important;
          }
          img {
            width: 0.7rem;
            height: 0.7rem;
            padding-top: 0.15rem;
            padding-right: 0.1rem;
          }
        }
      }
    }
  }
  .number3 {
    height: 100vh;
    img {
      position: absolute;
      height: 100vh;
    }
    .contain {
      position: absolute;
      width: 60%;
      margin: 3% 20%;
      .Rem0_4 {
        margin-bottom: 5%;
      }
      div {
        margin-bottom: 2%;
        .Rem0_28 {
          display: flex;
          align-items: center;
          span {
            height: 0.28rem;
            width: 0.28rem;
            text-align: center;
            line-height: 0.26rem;
            background: #3a78d3;
            color: white;
            border-radius: 50px;
            font-size: 0.26rem !important;
            margin-right: 5px;
          }
        }
        .Rem0_20 {
          line-height: 25px;
          color: #838690;
          padding-left: 25px;
        }
      }
    }
  }
  .number4 {
    position: relative;
    > img {
      width: 100%;
    }
    .contain {
      position: absolute;
      top: 0;
      .Rem0_4 {
        padding: 5.5% 0;
      }
      .boxdiv {
        display: flex;
        width: 90%;
        margin: 4% 5% 0 5%;

        div {
          flex: 1;
          margin: 1.4%;
          background: white;
          box-shadow: 0 0 10px 5px #e7eef9;
          padding: 4% 1.5% 1.8% 1.5%;
          text-align: center;
          border-radius: 0.18rem;
          img {
            width: 0.85rem;
          }
          p {
            line-height: normal !important;
          }
          .Rem0_28 {
            height: 0.92rem;
            padding-top: 8%;
          }
          .Rem0_20 {
            color: #666666;
          }
        }
      }
    }
  }
  .number5 {
    position: relative;
    > img {
      width: 100%;
    }
    .contain {
      position: absolute;
      top: 0;
      width: 100%;
      .Rem0_4 {
        margin-top: 4%;
      }
      img {
        position: absolute;
        right: 1%;
        top: -148%;
        width: 16%;
      }
      span{
        cursor: default;
      }
      .text1 {
        margin-top: 17.8%;
        span:nth-of-type(1) {
          width: 2.8rem;
          left: 15.5%;
        }
        span:nth-of-type(2) {
          left: 37.5%;
        }
        span:nth-of-type(3) {
          left: 54%;
          width: 2rem;
        }
        span:nth-of-type(4) {
          left: 71%;
          width: 2rem;
        }
      }
      .text2 {
        margin-top: 9.7%;
        height: 1rem;
        span:nth-of-type(1) {
          width: 2rem;
          left: 17%;
        }
        span:nth-of-type(2) {
          width: 2rem;
          left: 36%;
        }
        span:nth-of-type(3) {
          left: 54%;
          width: 2rem;
        }
        span:nth-of-type(4) {
          left: 72.2%;
          width: 1.6rem;
        }
      }
      .text {
        position: relative;
        display: flex;
        align-items: center;

        span {
          position: absolute;
          color: white;
          display: inline-block;
          line-height: 0.38rem !important;
          text-align: center;
        }
      }
    }
  }
}
</style>